import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private _isLoading = false;

  get isLoading() {
    return this._isLoading;
  }

  set isLoading(val: boolean){
    console.log('isLoading', this.isLoading);
    this._isLoading = val;
  }

  constructor() { }
}
