import { Ng2ImgMaxService } from 'blueimp-canvas-to-blob';
import { element } from 'protractor';
import { SelectItem } from 'primeng/primeng';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { CrmService } from 'src/app/service/crm.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { DatePipe } from '@angular/common';
import { debounceTime } from 'rxjs/operators';
import { RapportinoService } from 'src/app/service/rapportino.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-nuovo-rapportino',
  templateUrl: './nuovo-rapportino.component.html',
  styleUrls: ['./nuovo-rapportino.component.scss']
})
export class NuovoRapportinoComponent implements OnInit {

  @ViewChild('rappForm', {static: false}) rappForm: FormControl;

  @ViewChild('video', {static: false}) video: ElementRef;
  @ViewChild('canvas', {static: false}) canvas: ElementRef;

  public captures: Array<any>;

  clienti: SelectItem[];
  commesse: SelectItem[];
  tickets: SelectItem[];
  showCamera = false;
  displayImagePopup = false;
  largeImageSrc = '';
  editing = false;
  sending = false;

  constructor(private breadcrumb: BreadcrumbService, private crm: CrmService, private router: Router,
              private datepipe: DatePipe, public rapportino: RapportinoService, private ref: ChangeDetectorRef) {
    this.captures = [];

    if (this.rapportino && this.rapportino.rappData && this.rapportino.rappData.clienteId) {
      this.editing = true;
    }
  }

  ngOnInit() {

    let breadcrumbItems = [
        { label: 'Lista Rapportini', routerLink: ['/lista-rapportini'] },
        { label: 'Nuovo' }
    ];
    if (this.editing) {
      breadcrumbItems = [
          { label: 'Lista Rapportini', routerLink: ['/lista-rapportini'] },
          { label: 'Modifica' }
      ];
    }

    this.breadcrumb.setItems(breadcrumbItems);

    if (this.rapportino.cliente && this.rapportino.commessa && this.commesse === undefined) {
      this.commesse = JSON.parse(localStorage.getItem('commesse'));
    }
    if (this.rapportino.cliente && this.rapportino.ticket && this.tickets === undefined) {
      this.tickets = JSON.parse(localStorage.getItem('tickets'));
    }

    if (this.editing) {

      const ev = {
        value : this.rapportino.rappData.clienteId
      };
      this.selectedClient(ev);
    } else {
      this.rapportino.dateValue = this.datepipe.transform(new Date(), 'dd/MM/yyyy');

      this.crm.getNewRapportinoInfo().subscribe(
        (res: any) => {
          this.rapportino.newRappInfo = res;
        }
      );
    }

    // console.log('commesse', this.commesse);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.rapportino.lat = position.coords.latitude.toString();
        this.rapportino.lon = position.coords.longitude.toString();
      } );
    } else {
      console.log('GPS not supported');
    }
  }

  clearCliente() {
    this.rapportino.cliente = null;
    this.rapportino.commessa = null;
    this.commesse = null;
    this.rapportino.ticket = null;
    this.tickets = null;
  }

  searchClienti(event) {
    this.crm.getClienti(event.query).pipe(
      debounceTime(1000)
    ).subscribe(
      (res: any) => {
        this.clienti = res;
      }
    );
  }

  selectedClient(event) {
    this.rapportino.email = this.rapportino.cliente.email;
    this.crm.getCommesseByClienteId(event.value).subscribe(
      (res: any) => {
        this.commesse = [{label: '', value: null}, {label: 'Nessuna comm.', value: -1}].concat(res);
        localStorage.setItem('commesse', JSON.stringify(this.commesse));
      }
    );
    this.crm.getTicketsByClienteId(event.value).subscribe(
      (res: any) => {
        this.tickets = [{label: '', value: null}, {label: 'Nessun ticket.', value: -1}].concat(res);
        localStorage.setItem('tickets', JSON.stringify(this.tickets));
      }
    );
  }

  commessaChange() {
    if (this.rapportino.commessa.value == null) {
      this.rapportino.commessa = null;
      this.rapportino.noteCommessa = null;
    }
  }

  ticketChange() {
    if (this.rapportino.ticket.value == null) {
      this.rapportino.ticket = null;
    }
  }

  goNext() {
    if (!this.rappForm.valid) {
      alert('Campi obbligatori mancanti');
    } else {
      this.rapportino.storeRapp();
      this.router.navigate(['firma-rapportino']);
    }
  }

  cancel() {
    this.rapportino.resetRapp();
    this.rapportino.clearStoredRapp();
    this.router.navigate(['lista-rapportini']);
  }

  openCamera() {
    this.showCamera = true;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            // this.video.nativeElement.src = window.URL.createObjectURL(stream);
            this.video.nativeElement.srcObject = stream;
            this.video.nativeElement.play();
            const context = this.canvas.nativeElement.getContext('2d');
            context.canvas.width = this.video.nativeElement.clientWidth;
            context.canvas.height = this.video.nativeElement.clientHeight;
            // document.getElementById('canvas').style.width = '640px';
            // document.getElementById('canvas').style.height = '480px';
        });
    }
  }

  closeCamera() {
    this.video.nativeElement.srcObject.getTracks().forEach(track => track.stop());
    this.showCamera = false;
  }

  public capture() {
      const context = this.canvas.nativeElement.getContext('2d').drawImage(this.video.nativeElement, 0, 0);
      const img = this.canvas.nativeElement.toDataURL('image/png');
      this.captures.push(img);
      console.log('img', img);
  }

  compressImage(src, maxLengthSize) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        console.log('orig size', img.width, img.height);
        let w = 0;
        let h = 0;

        if (img.width > img.height) {
          w = maxLengthSize;
          h = Math.round(w / (img.width / img.height));
        } else {
          h = maxLengthSize;
          w = Math.round(h / (img.height / img.width));
        }

        const elem = document.createElement('canvas');
        elem.width = w;
        elem.height = h;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, w, h);
        const data = ctx.canvas.toDataURL('image/jpeg', 0.7);
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }

  fileSelect($event) {
    const fileData = $event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(fileData);

    reader.onload = (event) => {
      this.compressImage(reader.result, 1000).then(compressed => {
        // console.log('compressed', compressed);
        const newImageObj = new Image();
        const previewUrl = compressed;
        this.rapportino.images.push(compressed);
      });
    };
  }

  displayImage($event) {
    // console.log($event);
    window.document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    this.displayImagePopup = true;
    this.largeImageSrc = $event.target.src;
  }

  closeImage() {
    window.document.getElementsByTagName('body')[0].style.overflow = '';
    this.displayImagePopup = false;
    this.largeImageSrc = '';
  }

  trashImage(img) {
    let imgToTrashIdx = -1;
    this.rapportino.images.forEach((value, index) => {
      if (value === img.src) {
        imgToTrashIdx = index;
      }
    });
    this.rapportino.images.splice(imgToTrashIdx, 1);
    window.document.getElementsByTagName('body')[0].style.overflow = '';
    this.displayImagePopup = false;
  }

  saveSosp() {
    this.sending = true;
    this.rapportino.stato = 'sospeso';
    this.crm.saveRapportino(this.rapportino.rappData, null).subscribe(
      (res: any) => {
        // console.log('saveRapportino', res);
        this.router.navigate(['rapportino-inviato']);
        this.sending = false;
      },
      (err: any) => {
        this.sending = false;
      }
    );
  }


}
