import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'app-firma',
  templateUrl: './firma.component.html',
  styleUrls: ['./firma.component.css']
})
export class FirmaComponent implements OnInit, AfterViewInit {

  @ViewChild('signature', {static: false}) signaturePad: SignaturePad;

  signed = false;

  signaturePadOptions = { // passed through to szimek/signature_pad constructor
    minWidth : 1,
    // maxWidth : 10,
    dotSize : 0.1,
    // backgroundColor: '#F7F7F7',
    backgroundColor: '#ffffff',
    // penColor: '#ff0000',
    // canvasWidth: 600,
    canvasHeight: 200
  };

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // this.signaturePad is now available
    // this.signaturePad.set('minWidth', 5); // set szimek/signature_pad options at runtime
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    console.log(this.signaturePad.toDataURL());
    this.signed = true;
  }

  drawStart() {
    // will be notified of szimek/signature_pad's onBegin event
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signed = false;
  }

}
