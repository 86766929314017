import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of, from } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { Router, RouterStateSnapshot, RouterState } from '@angular/router';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

    state: RouterState;

    constructor(private router: Router, private loaderService: LoaderService) {
        this.state = router.routerState;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        this.loaderService.isLoading = true;

        const clonedReq = req.clone();
        return  next.handle(clonedReq).pipe(
            map(event => {
              if (event instanceof HttpResponse) {
                this.loaderService.isLoading = false;
              }
              return event;
            }),
            catchError(
                (err: any) => {
                    localStorage.clear();
                    this.router.navigate(['login']);
                    return throwError('Sessione scaduta o problema di connessione.');
                }
            )
        );
    }
}
