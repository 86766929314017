import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirmaComponent } from '../firma/firma.component';
import { RapportinoService } from 'src/app/service/rapportino.service';
import { CrmService } from 'src/app/service/crm.service';

@Component({
  selector: 'app-firma-rapportino',
  templateUrl: './firma-rapportino.component.html',
  styleUrls: ['./firma-rapportino.component.css']
})
export class FirmaRapportinoComponent implements OnInit {

  @ViewChild('firma', {static: false}) firma: FirmaComponent;

  sending = false;

  constructor(private router: Router, public rapportino: RapportinoService, private crm: CrmService) { }

  ngOnInit() {

    if (!this.rapportino.exists) {
      this.rapportino.restoreRapp();

      if (!this.rapportino.exists) {
        this.router.navigate(['lista-rapportini']);
      }
    }
  }

  cancel() {
    this.rapportino.resetRapp();
    this.rapportino.clearStoredRapp();
    this.router.navigate(['lista-rapportini']);
  }

  goBack() {
    this.router.navigate(['nuovo-rapportino']);
  }

  goNext() {
    this.sending = true;
    this.rapportino.stato = 'completato';
    this.crm.saveRapportino(this.rapportino.rappData, this.firma.signaturePad.toDataURL()).subscribe(
      (res: any) => {
        // console.log('saveRapportino', res);
        this.router.navigate(['rapportino-inviato']);
        this.sending = false;
      },
      (err: any) => {
        this.sending = false;
      }
    );
  }

}
