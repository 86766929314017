import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CrmService {

  private _head: any;
  constructor(private http: HttpClient) {
    this._head = {'Content-Type': 'application/x-www-form-urlencoded'};
  }

  createBodyParams(method, ...args: any[]) {
    let body = new HttpParams();
    body = body.append('method', method);

    for (const arg of args) {
      body = body.append(arg[0], arg[1]);
    }

    const u = localStorage.getItem('user');

    if (u) {
      const uobj = JSON.parse(u);
      body = body.append('uid', uobj.id);
    }

    return body;
  }

  sendRequest(method, ...args: any[]) {
    const body = this.createBodyParams(method, ...args);

    return this.http.post(environment.API_URL_CSTM, body.toString(),
      {headers : this._head});
  }

  test() {
    return this.sendRequest('test');
  }

  getUserRapportini(offset, filter, sort) {

    if (sort === null) {
      sort = { field : 'num', dir : -1 };
    }

    return this.sendRequest('getUserRapportini', ['pageSize', 20], ['offset', offset],
      ['filter', JSON.stringify(filter)], ['sort', JSON.stringify(sort)]);
  }

  getNewRapportinoInfo() {
    return this.sendRequest('getNewRapportinoInfo');
  }

  getClienti(txt) {
    return this.sendRequest('getClienti', ['txt', txt]);
  }

  getCommesseByClienteId(id) {
    return this.sendRequest('getCommesseByClienteId', ['id', id]);
  }

  getTicketsByClienteId(id) {
    return this.sendRequest('getTicketsByClienteId', ['id', id]);
  }

  saveRapportino(rappData, signature) {
    const rappDataArray = [];
    let encImgs = '';
    const sep = '_____IMG_____';

    for (const img of rappData.images) {
      encImgs += encodeURIComponent(window.btoa(img)) + sep;
    }

    if (encImgs !== '') {
      rappData.images = encImgs.substring(0, encImgs.length - sep.length);
    }

    for (const property in rappData) {
      if (rappData.hasOwnProperty(property)) {
        rappDataArray.push([property, rappData[property]]);
      }
    }
    if (signature) {
      rappDataArray.push(['signature', encodeURIComponent(window.btoa(signature))]);
    }

    console.log('Saving rapportino 2', rappDataArray);
    return this.sendRequest('saveRapportino', ...rappDataArray);
  }

  getRapportinoById(rid) {
    return this.sendRequest('getRapportinoById', ['rid', rid]);
  }
}
