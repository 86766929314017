import { Component } from '@angular/core';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
})
export class AppLoginComponent {

  constructor(private auth: AuthService) { }

  loginFailed = false;

  onBlur(event: any) {

    const UltimaLogin = {
        addClass: function(element, className) {
            if (element.classList) {
                element.classList.add(className);
            } else {
                element.className += ' ' + className;
            }
        },

        removeClass: function(element, className) {
            if (element.classList) {
                element.classList.remove(className);
            } else {
                element.className = element.className.replace(new RegExp('(^|\\b)' +
                className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        }
    };

    if (event.target.value !== '') {
        UltimaLogin.addClass(document.getElementById(event.target.id), 'ui-state-filled');
    } else {
        UltimaLogin.removeClass(document.getElementById(event.target.id), 'ui-state-filled');
    }
  }

  login(user, pass) {
    this.auth.login(user, pass).subscribe(
        (val) => { this.loginFailed = false; },
        (err) => { this.loginFailed = true; }
    );
  }
}
