import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rapportino-inviato',
  templateUrl: './rapportino-inviato.component.html',
  styleUrls: ['./rapportino-inviato.component.css']
})
export class RapportinoInviatoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToList() {
    this.router.navigate(['/']);
  }

}
