import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { mergeMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { RapportinoService } from './rapportino.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  returnUrl: string;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router) {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get loggedUser() {
    const u = JSON.parse(localStorage.getItem('user'));
    return u;
  }

  isLoggedIn() {
      return localStorage.getItem('user') !== null;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }

  login(user,pass) {

    const body = new HttpParams()
      .set('method', 'login')
      .set('username', user)
      .set('password', pass);
    // body.set('input_type', 'JSON');
    // body.set('response_type', 'JSON');
    const head = new Headers({'Content-Type': 'application/x-www-form-urlencoded'});

    const p = this.http.post(environment.API_URL_CSTM, body.toString(),
      {headers : {'Content-Type': 'application/x-www-form-urlencoded'}});
    p.subscribe(
        (response: any) => {
            console.log('outer');
            console.dir(response);
            console.dir(this.returnUrl);

            // console.log('return url: ',this.returnUrl);
            if (this.returnUrl !== undefined) {
                this.router.navigate([this.returnUrl]);
            } else {
                this.router.navigate(['/']);
            }
            // // console.log('Success');
            // // console.dir(response.access_token);
            localStorage.setItem('user', JSON.stringify(response.responseData));
            // // return this.getUserByName(user);
        },
        error => {
            console.log('Error');
            console.dir(error);
            console.dir(body.toString());
        }
    );
    return p;
  }
}
