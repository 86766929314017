import { SelectItem } from 'primeng/primeng';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { isInt } from '@fullcalendar/core';

@Injectable({
  providedIn: 'root'
})
export class RapportinoService {

  id: string;
  dateValue: string;
  newRappInfo: any;
  cliente: any;
  commessa: any;
  ticket: any;
  sedeImpianto: string;
  respImpianto: string;
  cellResponsabile: string;
  descrizione: string;
  _oraInizio: string;
  _oraFine: string;
  _orePausa: string;
  _oreLav: string;
  _oreViaggio: string;
  _km = 0;
  esiti: SelectItem[];
  esito = null;
  note: string;
  noteCommessa: string;
  email = '';
  lat = '';
  lon = '';
  images = [];
  stato = 'completato';

  loadedNumIntervento = '';

  constructor(private auth: AuthService) {
    this.esiti = [
      { label: '', value: null },
      { label: 'Sì', value: true },
      { label: 'No', value: false }
    ];
  }

  get exists() {
    return this.dateValue != null;
  }

  get esitoName() {
    return this.esiti.filter((e: any) => e.value === this.esito)[0].label;
  }

  get oraInizio() {
    return this._oraInizio;
  }

  set oraInizio(val: string) {
    this._oraInizio = this.normalizeHours(val);
  }

  get oraFine() {
    return this._oraFine;
  }

  set oraFine(val: string) {
    this._oraFine = this.normalizeHours(val);
  }

  get orePausa() {
    return this._orePausa;
  }

  set orePausa(val: string) {
    this._orePausa = this.normalizeMinutes(val);
  }

  get oreViaggio() {
    return this._oreViaggio;
  }

  set oreViaggio(val: string) {
    this._oreViaggio = this.normalizeMinutes(val);
  }

  get km() {
    return this._km;
  }

  set km(val: number) {
    this._km = (val < 0) ? -val : val;
  }

  get numIntervento() {
    if (this.newRappInfo) {
      return this.auth.loggedUser.idt + '/' + this.newRappInfo.idNextRapp;
    } else {
      return this.loadedNumIntervento;
    }
  }

  get tecnico() {
    return this.auth.loggedUser.full_name;
  }

  get oreLav() {
    if (this.oraInizio && this.oraFine && this.orePausa) {

      const hi = parseInt(this.oraInizio.split(':')[0], 10);
      const mi = parseInt(this.oraInizio.split(':')[1], 10);

      const hf = parseInt(this.oraFine.split(':')[0], 10);
      const mf = parseInt(this.oraFine.split(':')[1], 10);

      const hp = parseInt(this.orePausa.split(':')[0], 10);
      const mp = parseInt(this.orePausa.split(':')[1], 10);

      let ml = mf - mi - mp;
      let hl = hf - hi - hp - ( (ml >= 0) ? 0 : 1 );
      ml = (ml >= 0) ? ml : ml + 60;
      hl = (hl >= 0) ? hl : hl + 24;

      return this.pad(hl, 2) + ':' + this.pad(ml, 2);

    } else {
      return null;
    }
  }

  pad(num: number, size: number): string {
      let s = num + '';
      while (s.length < size) {
        s = '0' + s;
      }
      return s;
  }

  normalizeHours(hm: string) {

    if (!hm) {
      return hm;
    }

    for (let i = 0; i < hm.length; i++) {
      const c = hm.charAt(i);
      if (!isInt(c) && c !== ':') {
        return hm;
      }
    }

    const hSm = hm.split(':');

    const h = parseInt(hSm[0], 10);
    const newh = (h > 23) ? 23 : h;

    const m = parseInt(hSm[1], 10);
    const newm = (m > 59) ? 59 : m;

    return this.pad(newh, 2) + ':' + this.pad(newm, 2);
  }

  normalizeMinutes(hm: string) {

    if (!hm) {
      return hm;
    }

    for (let i = 0; i < hm.length; i++) {
      const c = hm.charAt(i);
      if (!isInt(c) && c !== ':') {
        return hm;
      }
    }

    const hSm = hm.split(':');

    const m = parseInt(hSm[1], 10);
    const newm = (m > 59) ? 59 : m;

    return hSm[0] + ':' + this.pad(newm, 2);
  }

  get rappData() {
    return {
      rid: this.id,
      stato: this.stato,
      dateValue: this.dateValue,
      newRappInfo: this.newRappInfo,
      numIntervento: this.numIntervento,
      cliente: this.cliente,
      commessa: this.commessa,
      ticket: this.ticket,
      clienteId: this.cliente ? this.cliente.value : null,
      commessaId: this.commessa ? this.commessa.value : null,
      ticketId: this.ticket ? this.ticket.value : null,
      sedeImpianto: this.sedeImpianto,
      respImpianto: this.respImpianto,
      cellResponsabile: this.cellResponsabile,
      descrizione: this.descrizione,
      oraInizio: this.oraInizio,
      oraFine: this.oraFine,
      orePausa: this.orePausa,
      oreLav: this.oreLav,
      oreViaggio: this.oreViaggio,
      km: this.km,
      esito: this.esito,
      note: this.note,
      noteCommessa: this.noteCommessa,
      email: this.email,
      lat: this.lat,
      lon: this.lon,
      images: this.images
    };
  }

  set rappData(val: any) {
      if (!val) {
        return;
      }
      this.id = val.rid;
      this.stato = val.stato;
      this.dateValue = val.dateValue;
      this.newRappInfo = val.newRappInfo;
      this.cliente = val.cliente;
      this.commessa = val.commessa;
      this.ticket = val.ticket;
      this.sedeImpianto = val.sedeImpianto;
      this.respImpianto = val.respImpianto;
      this.cellResponsabile = val.cellResponsabile;
      this.descrizione = val.descrizione;
      this.oraInizio = val.oraInizio;
      this.oraFine = val.oraFine;
      this.orePausa = val.orePausa;
      this.oreViaggio = val.oreViaggio;
      this.km = val.km;
      this.esito = val.esito;
      this.note = val.note;
      this.noteCommessa = val.noteCommessa;
      this.email = val.email;
      this.lat = val.lat;
      this.lon = val.lon;
      this.loadedNumIntervento = val.numIntervento;
      this.images = val.immagini ? val.immagini : [];
  }

  resetRapp() {
    this.id = null;
    this.stato = 'completato';
    this.dateValue = null;
    this.newRappInfo = null;
    this.cliente = null;
    this.commessa = null;
    this.ticket = null;
    this.sedeImpianto = null;
    this.respImpianto = null;
    this.cellResponsabile = null;
    this.descrizione = null;
    this.oraInizio = null;
    this.oraFine = null;
    this.orePausa = null;
    this._oreLav = null;
    this.oreViaggio = null;
    this.km = 0;
    this.esito = null;
    this.note = null;
    this.noteCommessa = null;
    this.email = '';
    this.lat = '';
    this.lon = '';
    this.images = [];
  }

  storeRapp() {
    localStorage.setItem('rapp', JSON.stringify(this.rappData));
  }

  restoreRapp() {
    this.rappData = JSON.parse(localStorage.getItem('rapp'));
  }

  clearStoredRapp() {
    localStorage.removeItem('rapp');
  }


}
