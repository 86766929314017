import { Component, OnInit } from '@angular/core';
import { CrmService } from 'src/app/service/crm.service';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { RapportinoService } from 'src/app/service/rapportino.service';

@Component({
  selector: 'app-lista-rapportini',
  templateUrl: './lista-rapportini.component.html',
  styleUrls: ['./lista-rapportini.component.css']
})
export class ListaRapportiniComponent implements OnInit {

  cols = [
    { field: 'num', header: 'Rapportino' },
    { field: 'rdate', header: 'Data' },
    // { field: 'ora_inizio', header: 'Inizio' },
    // { field: 'ora_fine', header: 'Fine' },
    // { field: 'ore_pausa', header: 'Ore pausa' },
    // { field: 'ore_viaggio', header: 'Ore viaggio' },
    // { field: 'ore_lavorate', header: 'Ore lavorate' },
    // { field: 'ore_fatturare', header: 'Ore fatturate' },
    { field: 'aname', header: 'Cliente' },
    { field: 'cname', header: 'Commessa' }
  ];

  rapportini = [];
  selectedRapportino: any;
  rapportiniNum = 0;

  rappFilter = null;
  rappSort = null;

  constructor(private breadcrumb: BreadcrumbService, private crm: CrmService, 
              private router: Router, private rapportino: RapportinoService) {
    this.breadcrumb.setItems([
        { label: 'Lista Rapportini' }
    ]);
  }

  ngOnInit() {
    this.resetFilters();
    this.rapportino.resetRapp();
    this.rapportino.clearStoredRapp();
  }

  loadRapportini() {
    this.crm.test().subscribe(
      (res: any) => {
        console.log('res', res);
      }
    );
  }

  loadRapportiniLazy($event) {
    this.getRapportini($event.first);
  }

  getRapportini(offset, filter= {}, sort= null, quoteType= null) {
    console.log('Starting getRapportini...');

    timer(500).pipe(
      switchMap(() => this.crm.getUserRapportini(offset, filter, this.rappSort))
    ).subscribe(
      (res: any) => {
        console.log('getRapportini', res);
        this.rapportiniNum = res.count;
        this.rapportini = res.rapp;

       // this.selectedQuote = res;
        // this.isLoading.emit(false);
      }
    );
  }

  filterTable() {
    console.log('filterTable', this.rappFilter);

    // if (this.quotesFilter.date != null) {
    //   this.quotesFilter.date[0] = formatDate(this.quotesFilter.date[0], 'yyyy-MM-dd', 'en-US');
    //   this.quotesFilter.date[1] = (this.quotesFilter.date[1] != null) ? formatDate(this.quotesFilter.date[1], 'yyyy-MM-dd', 'en-US') :
    //                               formatDate(this.quotesFilter.date[0], 'yyyy-MM-dd', 'en-US');
    // }

    this.getRapportini(0, this.rappFilter, this.rappSort);
  }

  sortRapp(field) {

    if (this.rappSort.field === field) {
      this.rappSort.dir *= -1;
    } else {
      this.rappSort.dir = 1;
    }
    this.rappSort.field = field;

    this.filterTable();
  }

  resetFilters() {
    this.rappSort = {
      field : 'num',
      dir : -1
    };

    this.rappFilter = {
      rname: '',
      rdate: null,
      aname: '',
      cname: '',
      num: ''
    };
  }

  addRapp() {
    this.router.navigate(['nuovo-rapportino']);
  }

  selectRow(rid, action = 'view') {
    this.crm.getRapportinoById(rid).subscribe(
      (res: any) => {

        const decodedImgs = [];
        if (res.immagini) {
          for (const img of res.immagini) {
            decodedImgs.push('data:image/jpeg;base64,' + decodeURIComponent(img));
          }
        }
        res.immagini = decodedImgs;

        this.rapportino.rappData = res;

        this.rapportino.storeRapp();

        console.log('getRapportinoById', this.rapportino.rappData, this.rapportino.email);

        if (action === 'view') {
          this.router.navigate(['dettaglio-rapportino']);
        } else if (action === 'edit') {
          this.router.navigate(['nuovo-rapportino']);
        }
      }
    );
  }

}
