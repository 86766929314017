import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { AuthService } from './service/auth.service';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="topbar clearfix">
            <div class="topbar-left">
                <div class="logo"></div>
            </div>

            <div class="topbar-right">
                <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
                    <i></i>
                </a>

                <div id="rightpanel-menu-button" style="text-align: right">
                    <div>{{ initials }}</div>
                    <small>{{ fullName }}</small>
                </div>

            </div>
        </div>
    `
})
export class AppTopbarComponent {

    initials = '';
    fullName = '';

    constructor(public app: AppMainComponent, private auth: AuthService) {
      const u = localStorage.getItem('user');

      if (u) {
        const uObj = JSON.parse(u);
        const userFullName = uObj.full_name;
        this.fullName = userFullName;
        const parts = userFullName.split(' ');

        this.initials = (((uObj.first_name) ? uObj.first_name.charAt(0) : '') +
                            ((uObj.last_name) ? uObj.last_name.charAt(0) : '')).toUpperCase();

        // console.log(parts[0]); // Stampa il nome utente

        // this.initials += parts[0] ? parts[0].charAt(0).toUpperCase() + (parts[1] ? parts[1].charAt(0).toUpperCase() : '') : '';
      }

    }

}
