import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private router: Router, private auth: AuthService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

      if (!this.auth.isLoggedIn()) {
          localStorage.clear();
          // this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
          this.router.navigate(['login']);
      } else {
          return true;
      }
  }
}
