import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RapportinoService } from 'src/app/service/rapportino.service';

@Component({
  selector: 'app-dettaglio-rapportino',
  templateUrl: './dettaglio-rapportino.component.html',
  styleUrls: ['./dettaglio-rapportino.component.css']
})
export class DettaglioRapportinoComponent implements OnInit {

  constructor(private router: Router, public rapportino: RapportinoService) { }

  ngOnInit() {
    if (!this.rapportino.exists) {
      this.rapportino.restoreRapp();
      if (!this.rapportino.exists) {
        this.router.navigate(['lista-rapportini']);
      }
    }
  }

  goBack() {
    this.rapportino.resetRapp();
    this.rapportino.clearStoredRapp();
    this.router.navigate(['lista-rapportini']);
  }

}
