import { RouteGuardService } from './service/route-guard.service';
import { NuovoRapportinoComponent } from './rapportini/nuovo-rapportino/nuovo-rapportino.component';
import { ListaRapportiniComponent } from './rapportini/lista-rapportini/lista-rapportini.component';
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardDemoComponent } from './demo/view/dashboarddemo.component';
import { SampleDemoComponent } from './demo/view/sampledemo.component';
import { FormsDemoComponent } from './demo/view/formsdemo.component';
import { DataDemoComponent } from './demo/view/datademo.component';
import { PanelsDemoComponent } from './demo/view/panelsdemo.component';
import { OverlaysDemoComponent } from './demo/view/overlaysdemo.component';
import { MenusDemoComponent } from './demo/view/menusdemo.component';
import { MessagesDemoComponent } from './demo/view/messagesdemo.component';
import { MiscDemoComponent } from './demo/view/miscdemo.component';
import { EmptyDemoComponent } from './demo/view/emptydemo.component';
import { ChartsDemoComponent } from './demo/view/chartsdemo.component';
import { FileDemoComponent } from './demo/view/filedemo.component';
import { UtilsDemoComponent } from './demo/view/utilsdemo.component';
import { DocumentationComponent } from './demo/view/documentation.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { GuestComponent } from './pages/guest/guest.component';
import { FirmaRapportinoComponent } from './rapportini/firma-rapportino/firma-rapportino.component';
import { DettaglioRapportinoComponent } from './rapportini/dettaglio-rapportino/dettaglio-rapportino.component';
import { RapportinoInviatoComponent } from './rapportini/rapportino-inviato/rapportino-inviato.component';

export const routes: Routes = [
    { path: '', component: AppMainComponent, canActivate: [RouteGuardService],
        children: [
            { path: '', component: ListaRapportiniComponent },
            { path: 'lista-rapportini', component: ListaRapportiniComponent },
            { path: 'nuovo-rapportino', component: NuovoRapportinoComponent },
            { path: 'firma-rapportino', component: FirmaRapportinoComponent },
            { path: 'dettaglio-rapportino', component: DettaglioRapportinoComponent },
            { path: 'rapportino-inviato', component: RapportinoInviatoComponent }
        ]
    },
    {
        path: '',
        component: GuestComponent,
        children: [
            { path: 'login', component: AppLoginComponent }
        ]
    }

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
