import { Component, Input, OnInit } from '@angular/core';
import { RapportinoService } from 'src/app/service/rapportino.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-riepilogo',
  templateUrl: './riepilogo.component.html',
  styleUrls: ['./riepilogo.component.scss']
})
export class RiepilogoComponent implements OnInit {

  // displayImagePopup = false;
  // largeImageSrc = '';
  @Input('signatureScreen') signatureScreen = false;

  constructor(public rapportino: RapportinoService) { }

  ngOnInit() {
    
  }

  // displayImage($event) {
  //   window.document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  //   this.displayImagePopup = true;
  //   this.largeImageSrc = $event.target.src;
  // }

  // closeImage() {
  //   window.document.getElementsByTagName('body')[0].style.overflow = '';
  //   this.displayImagePopup = false;
  //   this.largeImageSrc = '';
  // }

}
